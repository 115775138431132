.redirection-message-container {
  max-width: 700px;
  align-self: center;
  width: 100%;

  .redirect-message {
    margin: 1rem 1.5rem 0rem 1.5rem;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease-in-out;
    -moz-transition: opacity 0.25s ease-in-out;
    -o-transition: opacity 0.25s ease-in-out;
    transition: opacity 0.25s ease-in-out;

    &.mounted {
      opacity: 1;
    }
  }
}
