.next-action-modal {
  // top: 0 !important;
  height: 75vh;

  .modal-content {
    height: 90%;
  }

  .modal-footer {
    padding-top: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .content {
    height: 100%;
    overflow-y: auto;
  }
}
