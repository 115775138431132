.header {
  max-width: 650px;
  margin-top: 2rem;

  width: 100%;
  align-self: center;
  margin-bottom: 2rem;

  display: flex;

  img.image {
    align-self: flex-start;
    width: 100%;
    // height: 100%;
    // margin-right: 2rem;
    border: 1px solid #afbbca;
    border-radius: 4px;
  }

  .campaign-details-image {
    margin-right: 2rem;
    width: 30%;
  }

  .campaign-details {
    width: 70%;

    .campaign-name {
      margin-bottom: 0;
    }

    h1 {
      margin-bottom: 0;
    }
  }
}

@media only screen and (max-device-width: 767px) {
  .header {
    flex-direction: column;

    margin-bottom: 0;
    margin-top: 1rem;

    img.image {
      width: 89%;
      // margin-right: 2rem;
      margin-left: 1.2rem;
      margin-bottom: 1rem;
    }

    .campaign-details-image {
      width: 100%;
    }

    .campaign-details {
      width: 100%;

      .campaign-name {
        margin-top: 1rem;
        margin-bottom: 0;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
      }

      .campaign-description {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
      }
    }
  }
}
