.credit-card-form {
  height: 100%;
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  width: 100%;
  max-width: 700px;
  margin: auto;
  opacity: 0;

  -webkit-transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -o-transition: opacity 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out;

  .height-100 {
    height: 100% !important;
  }

  &.mounted {
    opacity: 1;
  }
}
