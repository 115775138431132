.amount-step {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin: auto;
  max-width: 700px;
  width: 100%;
  align-self: center;

  .Input-container {
    width: 100%;
  }
}
