.failed-donate {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin: auto;
  height: 30rem;
  padding-top: 2rem;
  max-width: 700px;
  width: 100%;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
