.loading-campaign {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin: auto;
  max-width: 700px;
  width: 100%;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-top: 1rem;
    margin-bottom: 0;
  }
}
