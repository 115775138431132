.footer {
  // position: absolute;
  // position: sticky;
  // bottom: 0;
  background-color: #31a27c;
  // width: 100vw;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;

  .footer-container {
    display: flex;
    justify-content: space-between;
    // max-width: 960px;
    max-width: 700px;
    width: 100%;
    align-self: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    .left {
      .lock-icon {
        color: #ffffff;
        margin-right: 0.7rem;
      }

      span {
        color: #ffffff;
        font-size: 0.7rem;
        font-weight: 500;
      }
    }

    .right {
      color: #ffffff;
      font-size: 0.7rem;
      font-weight: 500;

      span {
        font-weight: 500;
        font-size: 0.7rem;
        color: #ffffff;
        position: relative;
        top: 0.29rem;
      }

      img {
        margin-left: 0.2rem;
        width: 5rem;
        margin-bottom: -0.5rem;
      }
    }
  }
}
