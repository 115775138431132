.donor-information-step {
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  // max-width: 960px;
  max-width: 700px;
  width: 100%;
  align-self: center;
  margin: auto;
}
