.donate-step {
  margin-top: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  // max-width: 960px;
  max-width: 700px;
  width: 100%;
  align-self: center;

  .donate-button {
    width: 100%;
    padding-top: 0.7rem !important;
    padding-bottom: 0.7rem !important;

    & .button-content {
      display: block !important;
    }
  }
}
