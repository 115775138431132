.amount-button {
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;

  & .button-content {
    display: block !important;
  }

  &.left-amount-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 1px solid #ffffff;
  }

  &.middle-amount-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-left: 1px solid #ffffff;
    border-right: 1px solid #ffffff;
  }

  &.right-amount-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 1px solid #ffffff;
  }
}
